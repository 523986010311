<template>
  <v-form @submit.prevent="() => filteredOrganizations.length ? loginOrganization(filteredOrganizations[0]._id) : undefined">
    <v-text-field
      v-model.lazy="searchString"
      density="comfortable"
      class="mb-2"
      :label="$t('search')"
      prepend-inner-icon="search"
      :placeholder="$t('search')"
      single-line
      autofocus
      autocomplete="off"
      aria-autocomplete="none"
      hide-details
    />

    <div v-if="organizations.length === 0" class="text-medium-emphasis text-center">{{ $t('noOrganizations') }}</div>
    <div v-else-if="filteredOrganizations.length === 0" class="text-medium-emphasis text-center">{{ $t('noOrganizationsWithFilter') }}</div>

    <v-virtual-scroll style="height: 40vh" :items="filteredOrganizations" item-height="54">
      <template #default="{ item, index }">
        <v-list-item
          :key="index"
          class="rounded px-3"
          height="54"
          :base-color="hoverItemId === item._id ? 'primary' : undefined"
          @click="() => loginOrganization(item._id)"
          @mouseenter="() => hoverItemId = item._id"
          @mouseleave="() => { if (hoverItemId === item._id) { hoverItemId = null } }"
        >
          <template #prepend>
            <v-avatar variant="tonal" rounded>
              <v-icon :icon="`$${item.type?.toLowerCase()}`" />
            </v-avatar>
          </template>

          <template #title>{{ item.description }}</template>

          <template v-if="item.lastLogin" #subtitle>
            <v-icon icon="history" size="14px" class="mr-1"/>
            <span>{{ dateToReadableTimePast($i18n, item.lastLogin) }}</span>
          </template>

          <template #append>
            <v-icon v-if="isSuperAdmin" class="mr-2" :icon="`$domain_${item.domain.toLowerCase()}`" size="1.25rem" />

            <v-icon
              icon="arrow_forward"
              :class="hoverItemId === item._id ? 'arrow-forward--active' : ''"
              class="arrow-forward"
            />
          </template>
        </v-list-item>
      </template>
    </v-virtual-scroll>

    <v-card-actions v-if="currentUser?.userRole === UserRole.SUPER_ADMIN && !hideAdmin" class="mx-n3 mb-n3">
      <v-btn class="text-none" block color="admin" variant="tonal" prepend-icon="admin_panel_settings" @click="loginAdmin()">{{ $t("admin.title") }}</v-btn>
    </v-card-actions>

    <inner-loading :showing="pending || loggingIn" :label="pending ? '' : 'Logging in...'" />
  </v-form>
</template>

<script setup lang="ts">
import sortBy from "lodash-es/sortBy"
import { dateToReadableTimePast } from "~~/utils/date-helpers"
import { UserRole } from "~~/stores/user.store"

const props = defineProps<{ hideAdmin?: boolean }>()
const { hideAdmin } = toRefs(props)

const $i18n = useI18n()
const $route = useRoute()
const $router = useRouter()

const mainStore = useMainStore()
const authStore = useAuthStore()
const userStore = useUserStore()
const organizationStore = useOrganizationStore()

const { pending: pendingAuth, error, isSuperAdmin } = storeToRefs(authStore)
const { currentUser } = storeToRefs(userStore)

const { data: organizations, pending: pendingOrganizations } = useLazyAsyncData(
  "org_list",
  async () => organizationStore.getOrganizations(),
  { default: () => [] as Array<Organization>}
)
const { pending: pendingCurrentUser } = useLazyAsyncData(
  "org_list_user",
  async () => !!currentUser.value || (await userStore.getCurrentUser())
)

const searchString = ref("")
const hoverItemId = ref<string | null>(null)

const pending = computed(() => pendingAuth.value || pendingOrganizations.value || pendingCurrentUser.value)
const loggingIn = ref(false)

const filteredOrganizations = computed(() => {
  const searchText = searchString.value.toLowerCase()

  const mappedOrganizations = organizations.value
    .filter((org?: Organization) => {
      // No one can login to LABEL.
      if (org?.type === OrganizationType.LABEL) return false

      // Filter by search text - if any.
      if (searchText) return org?.description?.toLowerCase().includes(searchText)

      return true
    })
    .map((org) => ({
      ...org,
      lastLogin: currentUser.value?.lastLogins.find((s) => s.organization === org?._id)?.date,
    }))

  return sortBy(mappedOrganizations, (org) => org.lastLogin && -new Date(org.lastLogin).valueOf())
})

async function loginAdmin() {
  if (!isSuperAdmin.value) return

  await authStore.logoutOrganization()

  $router.push({ name: "admin" })
}

async function loginOrganization(organizationId: string) {
  await authStore.updateOrganization(organizationId)

  if (error.value) {
    mainStore.notify({
      title: $i18n.t("error"),
      text: $i18n.t(error.value),
      type: "error",
      closable: true,
    })

    return
  }

  loggingIn.value = true

  setTimeout(() => {
    if ($route.path !== "dashboard") {
      // Organization selected: go to dashboard or follow redirect
      location.href = $route.query.redirect as string || "/dashboard"
    } else {
      // Organization selected and already at dashboard: reload dashboard
      location.reload()
    }

    loggingIn.value = false
  }, 500)
}

watch([pendingOrganizations, currentUser], () => {
  if (pendingOrganizations.value || !currentUser.value) {
    return
  }

  if (organizations.value.length === 1 && currentUser.value?.userRole !== UserRole.SUPER_ADMIN) {
    loginOrganization(organizations.value[0]._id)
  }
})
</script>

<style>
.arrow-forward {
  transition: transform 0.05s ease-in-out;
}

.arrow-forward--active {
  transform: translateX(4px);
}
</style>